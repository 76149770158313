import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get } from 'lodash';
import { InputText } from 'primereact/inputtext';
import { TreeSelect } from 'primereact/treeselect';

import * as actions from '../../../store/modules/auth/actions';

import { displayErrors } from '../../../util/diversos';
import { Input100px, Input155px } from '../../../styles/inputGroup';

import Card from '../../../components/card';
import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import Loading from '../../loading';

export default function CadastroGrupoAcesso({ payloadCadastro, childToParentConsulta }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const id = get(payloadCadastro, 'id', 0);

  const [descricao, setDescricao] = useState('');

  const dadosKey = [
    { key: 'Tabelas',
      label: 'Tabelas',
      children: [
        { key: 'ConsultaProduto',
          label: 'Produtos',
          children: [
            { key: 'CadastrarProduto',
              label: 'Produto Cadastrar',
            },
            { key: 'EditarProduto',
              label: 'Produto Editar',
            },
            { key: 'ExcluirProduto',
              label: 'Produto Excluir',
            },
            { key: 'AlterarEstoqueProduto',
              label: 'Produto Alterar Estoque',
            },
          ],
        },
        { key: 'ConsultaParticipante',
          label: 'Participantes',
          children: [
            { key: 'CadastrarParticipante',
              label: 'Participante Cadastrar',
            },
            { key: 'EditarParticipante',
              label: 'Participante Editar',
            },
            { key: 'ExcluirParticipante',
              label: 'Participante Excluir',
            },
          ],
        },
        { key: 'ConsultaUnidadeMedida',
          label: 'Unidades de Medida',
          children: [
            { key: 'CadastrarUnidadeMedida',
              label: 'Unidade de Medida Cadastrar',
            },
            { key: 'EditarUnidadeMedida',
              label: 'Unidade de Medida Editar',
            },
            { key: 'ExcluirUnidadeMedida',
              label: 'Participante Excluir',
            },
          ],
        },
        { key: 'ConsultaGrupo',
          label: 'Grupos de Produtos',
          children: [
            { key: 'CadastrarGrupoProduto',
              label: 'Grupo de Produto Cadastrar',
            },
            { key: 'EditarGrupoProduto',
              label: 'Grupo de Produto Editar',
            },
            { key: 'ExcluirGrupoProduto',
              label: 'Grupo de Produto Excluir',
            },
          ],
        },
        { key: 'ConsultaSubGrupo',
          label: 'Sub-Grupos de Produtos',
          children: [
            { key: 'CadastrarSubGrupoProduto',
              label: 'Sub-Grupo de Produto Cadastrar',
            },
            { key: 'EditarSubGrupoProduto',
              label: 'Sub-Grupo de Produto Editar',
            },
            { key: 'ExcluirSubGrupoProduto',
              label: 'sub-Grupo de Produto Excluir',
            },
          ],
        },
        { key: 'ConsultaNaturezaOperacao',
          label: 'Natureza da Operação',
          children: [
            { key: 'CadastrarNaturezaOperacao',
              label: 'Natureza da Operação Cadastrar',
            },
            { key: 'EditarNaturezaOperacao',
              label: 'Natureza da Operação Editar',
            },
            { key: 'ExcluirNaturezaOperacao',
              label: 'Natureza da Operação Excluir',
            },
          ],
        },
        { key: 'ConsultaConversaoCfop',
          label: 'Conversão CFOP',
          children: [
            { key: 'CadastrarConversaoCfop',
              label: 'Conversão CFOP Cadastrar',
            },
            { key: 'EditarConversaoCfop',
              label: 'Conversão CFOP Editar',
            },
            { key: 'ExcluirConversaoCfop',
              label: 'Conversão CFOP Excluir',
            },
          ],
        },
        { key: 'ConsultaConversaoCsosnCst',
          label: 'Conversão CSOSN CST',
          children: [
            { key: 'CadastrarConversaoCsosnCst',
              label: 'Conversão CSOSN CST Cadastrar',
            },
            { key: 'EditarConversaoCsosnCst',
              label: 'Conversão CSOSN CST Editar',
            },
            { key: 'ExcluirConversaoCsosnCst',
              label: 'Conversão CSOSN CST Excluir',
            },
          ],
        },
        { key: 'ConsultaContaAnalitica',
          label: 'Conta Analítica Contábil ',
          children: [
            { key: 'CadastrarContaAnalitica',
              label: 'Conta Analítica Contábil Cadastrar',
            },
            { key: 'EditarContaAnalitica',
              label: 'Conta Analítica Contábil Editar',
            },
            { key: 'ExcluirContaAnalitica',
              label: 'Conta Analítica Contábil Excluir',
            },
          ],
        },
        { key: 'ConsultaGrupoAcesso',
          label: 'Grupo Acesso',
          children: [
            { key: 'CadastrarGrupoAcesso',
              label: 'Grupo Acesso Cadastrar',
            },
            { key: 'EditarGrupoAcesso',
              label: 'Grupo Acesso Editar',
            },
            { key: 'ExcluirGrupoAcesso',
              label: 'Grupo Acesso Excluir',
            },
          ],
        },
        { key: 'ConsultaOperador',
          label: 'Operador',
          children: [
            { key: 'CadastrarOperador',
              label: 'Operador Cadastrar',
            },
            { key: 'EditarOperador',
              label: 'Operador Editar',
            },
            { key: 'ExcluirOperador',
              label: 'Operador Excluir',
            },
          ],
        },
        { key: 'ConsultaVendedor',
          label: 'Vendedor',
          children: [
            { key: 'CadastrarVendedor',
              label: 'Vendedor Cadastrar',
            },
            { key: 'EditarVendedor',
              label: 'Vendedor Editar',
            },
            { key: 'ExcluirVendedor',
              label: 'Vendedor Excluir',
            },
          ],
        },
      ],
    },
    { key: 'Docs Fiscais',
      label: 'Docs Fiscais',
      children: [
        { key: 'NFe Emissao Propria',
          label: 'NF-e Emissão Própria',
          children: [
            { key: 'ConsultaNFePropria',
              label: 'Digitação de NF-e',
              children: [
                { key: 'CadastrarNFe',
                  label: 'NF-e Cadastrar',
                },
                { key: 'EditarNFe',
                  label: 'NF-e Editar',
                },
                { key: 'ExcluirNFe',
                  label: 'NF-e Excluir',
                },
              ],
            },
            { key: 'EnvioNFePropria',
              label: 'Envio de NF-e - consulta',
            },
            { key: 'EnviarNFe',
              label: 'NFe Enviar',
            },
          ],
        },
        { key: 'NFe Entrada',
          label: 'NF-e Entrada',
          children: [
            { key: 'ConsultaNFeTerceiro',
              label: 'Digitação de NF-e',
              children: [
                { key: 'CadastrarNFeTerceiro',
                  label: 'NF-e Cadastrar',
                },
                { key: 'EditarNFeTerceiro',
                  label: 'NF-e Editar',
                },
                { key: 'ExcluirNFeTerceiro',
                  label: 'NF-e Excluir',
                },
                { key: 'ColetarXMLTerceiro',
                  label: 'coletar XML entrada de nota',
                },
              ],
            },
          ],
        },
        { key: 'NFCe',
          label: 'NFC-e ',
          children: [
            { key: 'EmissaoNFCe',
              label: 'Emissão NFC-e',
            },
            { key: 'ConsultaNFCe',
              label: 'Consulta NFC-e',
            },
            { key: 'MonitorNFCe',
              label: 'Monitor NFC-e',
            },
            { key: 'EnviarNFCeMonitor',
              label: 'Enviar NFCe no monitor',
            },
          ],
        },
        { key: 'GerarSintegra',
          label: 'Gerar Arquivo Sintegra',
        },
        { key: 'ExportarXML',
          label: 'Exportar XML',
        },
        { key: 'StatusServico',
          label: 'Status do Serviço ',
        },
      ],
    },
    { key: 'Financeiro',
      label: 'Financeiro',
      children: [
        { key: 'PainelFinanceiro',
          label: 'Painel Financeiro',
        },
        { key: 'ConsultaMovimentacaoFinanceira',
          label: 'Consulta Movimentação Financeira',
          children: [
            { key: 'CadastrarMovimentacaoFinanceira',
              label: 'Cadastrar Movimentação Financeira',
            },
            { key: 'EditarMovimentacaoFinanceira',
              label: 'Editar Movimentação Financeira',
            },
            { key: 'ExcluirMovimentacaoFinanceira',
              label: 'Excluir Movimentação Financeira',
            },
            { key: 'QuitarMovimentacaoFinanceira',
              label: 'Quitar Movimentação Financeira',
            },
            { key: 'ConciliarMovimentacaoFinanceira',
              label: 'Conciliar Movimentação Financeira',
            },
            { key: 'CancelarConciliacaoMovimentacaoFinanceira',
              label: 'Cancelar Conciliação Movimentação Financeira',
            },
          ],
        },
        { key: 'ConsultaPrevisaoFinanceira',
          label: 'Consulta Previsão Financeira',
          children: [
            { key: 'CadastrarPrevisaoFinanceira',
              label: 'Cadastrar Previsão Financeira',
            },
            { key: 'EditarPrevisaoFinanceira',
              label: 'Editar Previsão Financeira',
            },
            { key: 'ExcluirPrevisaoFinanceira',
              label: 'Excluir Previsão Financeira',
            },
          ],
        },
        { key: 'ConsultaMovimentacaoBancaria',
          label: 'Consulta Movimentacao Bancaria',
          children: [
            { key: 'CadastrarMovimentacaoBancaria',
              label: 'Cadastrar Movimentação Bancária',
            },
            { key: 'EditarMovimentacaoBancaria',
              label: 'Editar Movimentação Bancária',
            },
            { key: 'ExcluirMovimentacaoBancaria',
              label: 'Excluir Movimentação Bancária',
            },
          ],
        },
        { key: 'ConsultaLivroCaixa',
          label: 'Consulta Livro Caixa',
          children: [
            { key: 'CadastrarLivroCaixa',
              label: 'Cadastrar Livro Caixa',
            },
            { key: 'EditarLivroCaixa',
              label: 'Editar Livro Caixa',
            },
            { key: 'ExcluirLivroCaixa',
              label: 'Excluir Livro Caixa',
            },
          ],
        },
        { key: 'ConsultaFluxoCaixa',
          label: 'Consulta Fluxo Caixa',
          children: [
            { key: 'CadastrarFluxoCaixa',
              label: 'Cadastrar Fluxo Caixa',
            },
            { key: 'EditarFluxoCaixa',
              label: 'Editar Fluxo Caixa',
            },
            { key: 'ExcluirFluxoCaixa',
              label: 'Excluir Fluxo Caixa',
            },
          ],
        },
        /* { key: 'Pagamento',
          label: 'Pagamento Mensalidade',
        }, */
      ],
    },
    { key: 'Relatorios',
      label: 'Relatórios',
      children: [
        { key: 'RelatorioMovimentacaoProduto',
          label: 'Relatório Movimentação Produto',
        },
      ],
    },
    { key: 'Consultas',
      label: 'Consultas',
      children: [
        { key: 'ConsultaFechamentoCaixa',
          label: 'Consulta Fechamento Caixa',
        },
        { key: 'VisualizarDetalhesFechamentoCaixa',
          label: 'Visualizar Fechamento de Caixa',
        },
        { key: 'ConsultaResumoDav',
          label: 'Consulta Resumo DAV',
        },
        { key: 'ConsultaResumoNfce',
          label: 'Consulta Resumo NFC-e',
        },
      ],
    },
    { key: 'Outros',
      label: 'Outros',
      children: [
        { key: 'ConfiguracaoSistema',
          label: 'Configuração Sistema',
        },
        { key: 'ConsultaMovimentacaoProduto',
          label: 'Consulta Movimentação Produto',
          children: [
            { key: 'CadastrarMovimentacaoProduto',
              label: 'Cadastrar Movimentação Produto',
            },
            { key: 'EditarMovimentacaoProduto',
              label: 'Editar Movimentação Produto',
            },
            { key: 'ExcluirMovimentacaoProduto',
              label: 'Excluir Movimentação Produto',
            },
            { key: 'ImprimirMovimentacaoProduto',
              label: 'Imprimir Movimentação Produto',
            },
            { key: 'entradaMovimentacaoEstoqueProduto',
              label: 'Movimentacao de Produto - Entrada estoque produto',
            },
          ],
        },
        { key: 'ConsultaOrdemServicoAparelho',
          label: 'Consulta Ordem de Servico Aparelho',
          children: [
            { key: 'CadastrarOrdemServicoAparelho',
              label: 'Cadastrar Ordem de Servico Aparelho',
            },
            { key: 'EditarOrdemServicoAparelho',
              label: 'Editar Ordem de Servico Aparelho',
            },
            { key: 'ExcluirOrdemServicoAparelho',
              label: 'Excluir Ordem de Servico Aparelho',
            },
            { key: 'FecharOrdemServicoAparelho',
              label: 'Fechar Ordem de Servico Aparelho',
            },
            { key: 'ImprimirOrdemServicoAparelho',
              label: 'Imprimir Ordem de Servico Aparelho',
            },
          ],
        },
        { key: 'AtualizaEstoque',
          label: 'Atualiza Estoque',
        },
        { key: 'EmpresaConsultaTicket',
          label: 'Tickets',
          children: [
            { key: 'CadastrarTicket',
              label: 'Cadastrar Ticket',
            },
            { key: 'EditarTicket',
              label: 'Editar Ticket',
            },
            { key: 'ExcluirTicket',
              label: 'Excluir Ticket',
            },
            { key: 'FecharTicket',
              label: 'Fechar Ticket',
            },
          ],
        },
        { key: 'AlterarSenha',
          label: 'AlterarSenha',
        },
      ],
    },
  ];

  // eslint-disable-next-line no-unused-vars
  const [nodeGrupoAcessoBody, setNodeGrupoAcessoBody] = useState(dadosKey);
  const [selectedNodeGrupoAcessoBody, setSelectedNodeGrupoAcessoBody] = useState(null);

  useEffect(() => {
    let newTitle = 'Grupo de Acesso - Cadastro';
    if (id) {
      newTitle = 'Grupo de Acesso - Edição';
    }

    dispatch(actions.titleHeaderChange({ newTitle }));

    if (!id) return;

    async function getData() {
      try {
        setIsLoading(true);

        const { data } = await axiosAdmin.get(`/grupoacessoheader/${id}`);

        const idUsuario = get(store.getState().auth.user, 'id', 0);
        if (data.idUsuario !== idUsuario) {
          navigate('/consultagrupoacesso');
          toast.error('Acesso negado');
        }

        let dadosSetKey = {};

        data.listaGrupoAcessoBody.forEach(element => {
          dadosSetKey = { ...dadosSetKey, [element.modulo]: { checked: true, partialChecked: true } };
        });

        setDescricao(data.descricao);
        setSelectedNodeGrupoAcessoBody(dadosSetKey);
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  }, [dispatch, id, navigate]);

  async function salvarRegistro() {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      const listaGrupoAcessoBody = [];

      if (selectedNodeGrupoAcessoBody !== null) {
        Object.keys(selectedNodeGrupoAcessoBody).forEach((item) => {
          listaGrupoAcessoBody.push(item);
        });
      }

      const shippingData = {
        idUsuario,
        descricao,
        listaGrupoAcessoBody,
      };

      let payloadDataTableList = {};

      if (id > 0) {
        payloadDataTableList = get(await axiosAdmin.put(`/grupoacessoheader/${id}`, shippingData), 'data', {});
        toast.success('Grupo de Acesso atualizado com sucesso');
      } else {
        payloadDataTableList = get(await axiosAdmin.post('/grupoacessoheader', shippingData), 'data', {});
        toast.success('Grupo de Acesso cadastrado com sucesso');
      }

      childToParentConsulta(payloadDataTableList, id);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar o registro');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    childToParentConsulta();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;

    if (!descricao) {
      formErros = true;
      toast.error('Descrição deve ser informada');
    }

    if (formErros) return;

    salvarRegistro();
  };

  const titulo = () => (id ? 'Grupo de Acesso - Alteração' : 'Grupo de Acesso - Inclusão');

  return (
    <div>
      <Loading isLoading={isLoading} />
      <Card>
        <div className="card mb-3">
          <h6 className="card-header" style={{ textAlign: 'center' }}>{titulo()}</h6>
        </div>
        <div className="row form-elo">
          <div className="col-9">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Descrição
              </Input100px>
              <InputText
                maxLength={40}
                value={descricao}
                onChange={e => setDescricao(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Empresa(s)
              </Input155px>
              <TreeSelect
                value={selectedNodeGrupoAcessoBody}
                className="form-control"
                selectionMode="checkbox"
                filter
                emptyMessage="Nenhum registro a ser exibido"
                options={nodeGrupoAcessoBody}
                onChange={(e) => setSelectedNodeGrupoAcessoBody(e.value)}
                placeholder="Selecione..."
              >
              </TreeSelect>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <button
              onClick={handleSubmit}
              type="button"
              className="btn btn-success"
            >
              <i className="pi pi-check"></i>
              Salvar
            </button>
            <button
              onClick={handleCancel}
              type="button"
              className="btn btn-danger"
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Card>
    </div>
  );
}
