/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { ToggleButton } from 'primereact/togglebutton';
import { get, orderBy, isEmpty, sortBy } from 'lodash';

import Modal from 'react-bootstrap/Modal';

import * as actions from '../../../store/modules/auth/actions';

import 'react-toastify/dist/ReactToastify.css';

import { displayErrors } from '../../../util/diversos';
import { fillColumns } from '../../../components/table';
import { Input155px, Input100px, InputCampoBusca155px } from '../../../styles/inputGroup';

// eslint-disable-next-line import/no-cycle
import CadastroProduto from './cadastroProduto';
import store from '../../../store/store';
import axiosAdmin from '../../../services/axiosAdmin';
import Card from '../../../components/card';
import Loading from '../../loading';

export default function ConsultaProduto({ menuMode, childToParent, somenteLeitura = false, empresaCnpj }) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [permitirAlteracaoEstoque, setPermitirAlteracaoEstoque] = useState(true);
  const [permitirCadastrar, setPermitirCadastrar] = useState(true);
  const [permitirEditar, setPermitirEditar] = useState(true);
  const [permitirExcluir, setPermitirExcluir] = useState(true);

  const [idProduto, setIdProduto] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [cnpjProduto, setCnpjProduto] = useState(0);

  const [totalRecords, setTotalRecords] = useState(0);
  const [modalHistoricoComprasShow, setModalHistoricoComprasShow] = useState(false);
  const [modalLogEstoqueShow, setModalLogEstoqueShow] = useState(false);
  const [modalLogEstoqueIndividualShow, setModalLogEstoqueIndividualShow] = useState(false);
  const [dataTableList, setDataTableList] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showCadastro, setShowCadastro] = useState(false);
  const [showAlteraEstoque, setShowAlteraEstoque] = useState(false);
  const [rowDataAlterarEstoque, setRowDataAlterarEstoque] = useState(null);
  const [alteraEstoqueDeposito, setAlteraEstoqueDeposito] = useState(0);
  const [alteraEstoquePrateleira, setAlteraEstoquePrateleira] = useState(0);
  const [payloadCadastro, setPayloadCadastro] = useState({});
  const [selectedItens, setSelectedItens] = useState(null);
  const [findField, setFindField] = useState('');
  const [sortField, setSortField] = useState('descricao');
  const [sortOrder, setSortOrder] = useState(null);
  const [expandedRows, setExpandedRows] = useState(null);
  const [multiEmpresa, setMultiEmpresa] = useState(false);
  const [cadastroSincronizado, setCadastroSincronizado] = useState(true);
  const [listaEmpresas, setListaEmpresas] = useState([]);
  const [cnpjEmpresa, setCnpjEmpresa] = useState('');
  const [inativos, setInativos] = useState(false);
  const [desabilitarCadastroProduto, setDesabilitarCadastroProduto] = useState(false);
  const [habilitarAlteracaoEstoqueFilial, setHabilitarAlteracaoEstoqueFilial] = useState(false);
  const [historicoComprasList, setHistoricoComprasList] = useState([]);
  const [logEstoqueList, setLogEstoqueList] = useState([]);
  const [logEstoqueTotalRecords, setLogEstoqueTotalRecords] = useState(0);
  const [logEstoqueIndividualTotalRecords, setLogEstoqueIndividualTotalRecords] = useState(0);
  const [columnsSetup, setColumnsSetup] = useState([
    { field: 'registro', header: 'Registro', sort: true },
    { field: 'descricao', header: 'Descrição', sort: true },
    { field: 'unidadeMedida.sigla', header: 'Unid', sort: true },
    { field: 'grupo.codigo', header: 'Grupo', sort: true },
    { field: 'listaEstoques.qtdeDeposito', header: 'Depósito', sort: false },
    { field: 'listaEstoques.qtdePrateleira', header: 'Prateleira', sort: false },
    { field: 'precoCustoReal', header: 'Pr Custo Real', sort: false },
    { field: 'precoVenda', header: 'Preço Venda', sort: false },
  ]);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: null,
  });

  const [lazyParamsLogEstoque, setLazyParamsLogEstoque] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: null,
  });

  const [lazyParamsLogEstoqueIndividual, setLazyParamsLogEstoqueIndividual] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: null,
    rowData: null,
  });

  let mostrarMensagemErro = true;

  async function getConfiguracaoSistema() {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      const params = `/configuracaosistema?idUsuario=${idUsuario}`;

      const response = await axiosAdmin.get(params);

      setDesabilitarCadastroProduto(get(response.data, 'desabilitarCadastroProduto', false));
      setHabilitarAlteracaoEstoqueFilial(get(response.data, 'habilitarAlteracaoEstoqueFilial', false));
    } catch (err) {
      if (mostrarMensagemErro) {
        displayErrors(err, dispatch, navigate, '/', '');
      }
      mostrarMensagemErro = false;
    } finally {
      setIsLoading(false);
    }
  }

  async function getData(searchFilter, filtrarCnpjEmpresa, page = 0, size = 10) {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let params = `/produto/search?idUsuario=${idUsuario}`;

      if (inativos) {
        params = `${params}&somenteAtivo=false`;
      }

      if (filtrarCnpjEmpresa !== '') {
        params = `${params}&cnpjEmpresa=${filtrarCnpjEmpresa}`;
      }

      if (searchFilter && searchFilter.findField !== '') {
        params = `${params}&${sortField}=${searchFilter.findField}`;
      }

      params = `${params}&page=${page}&size=${size}`;

      const response = await axiosAdmin.get(params);

      setDataTableList(response.data.content);
      setTotalRecords(response.data.totalElements);
    } catch (err) {
      if (mostrarMensagemErro) {
        displayErrors(err, dispatch, navigate, '/', '');
      }
      mostrarMensagemErro = false;
    } finally {
      setIsLoading(false);
    }
  }

  async function getHistoricoCompras(filtrarCnpjEmpresa, registroProduto) {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let params = `/produtocompra?idUsuario=${idUsuario}`;

      if (filtrarCnpjEmpresa !== '') {
        params = `${params}&cnpjEmpresa=${filtrarCnpjEmpresa}`;
      }

      if (registroProduto !== '') {
        params = `${params}&registroProduto=${registroProduto}`;
      }

      const response = await axiosAdmin.get(params);

      setHistoricoComprasList(response.data);
    } finally {
      setIsLoading(false);
    }
  }

  const getLogEstoque = async (page = 0, size = 10) => {
    try {
      const idUsuario = get(store.getState().auth.user, 'id', 0);

      // eslint-disable-next-line no-shadow
      const idProduto = get(selectedItens, 'id', 0);

      // eslint-disable-next-line no-shadow
      const cnpjProduto = get(selectedItens, 'cnpjEmpresa', '');

      let params = `/logestoque/search?idUsuario=${idUsuario}`;

      if (cnpjProduto !== '') {
        params = `${params}&cnpjEmpresa=${cnpjProduto.trim()}`;
      }

      if (idProduto !== '' && idProduto > 0) {
        params = `${params}&idProduto=${idProduto}`;
      }

      params = `${params}&page=${page}&size=${size}`;

      const response = await axiosAdmin.get(params);

      const _logEstoque = sortBy(response.data.content, 'id');

      // fazer calculo do estoque mov dep e mov prat
      const _logEstoqueList = [];

      _logEstoque.forEach(el => {
        // eslint-disable-next-line no-param-reassign
        el.movDeposito = Number(el.qtdeDepositoDepois) - Number(el.qtdeDepositoAntes);

        // eslint-disable-next-line no-param-reassign
        el.movPrateleira = Number(el.qtdePrateleiraDepois) - Number(el.qtdePrateleiraAntes);

        _logEstoqueList.push(el);
      });

      setLogEstoqueList(_logEstoqueList);
      setLogEstoqueTotalRecords(response.data.totalElements);
    } catch (err) {
      toast.info('Erro ao consultar log estoque.');
    }
  };

  // eslint-disable-next-line no-shadow
  const getLogEstoqueIndividual = async (idProduto, cnpjProduto, page = 0, size = 10) => {
    try {
      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let params = `/logestoque/search?idUsuario=${idUsuario}`;

      if (cnpjProduto !== '') {
        params = `${params}&cnpjEmpresa=${cnpjProduto.trim()}`;
      }

      if (idProduto !== '' && idProduto > 0) {
        params = `${params}&idProduto=${idProduto}`;
      }

      params = `${params}&page=${page}&size=${size}`;

      await axiosAdmin.get(params).then(response => {
        const _logEstoque = sortBy(response.data.content, 'id');

        // fazer calculo do estoque mov dep e mov prat
        const _logEstoqueList = [];

        _logEstoque.forEach(el => {
          // eslint-disable-next-line no-param-reassign
          el.movDeposito = Number(el.qtdeDepositoDepois) - Number(el.qtdeDepositoAntes);

          // eslint-disable-next-line no-param-reassign
          el.movPrateleira = Number(el.qtdePrateleiraDepois) - Number(el.qtdePrateleiraAntes);

          _logEstoqueList.push(el);
        });

        lazyParamsLogEstoqueIndividual.page = page;

        setLogEstoqueList(_logEstoqueList);
        setLogEstoqueIndividualTotalRecords(response.data.totalElements);
      });
    } catch (error) {
      toast.info('Erro ao consultar log estoque.');
    }
  };

  const handleHideModalLogEstoque = () => {
    setModalLogEstoqueShow(false);
  };

  const handleHideModalLogEstoqueIndividual = () => {
    setModalLogEstoqueIndividualShow(false);
  };

  const handleHideModalHistoricoCompras = () => {
    setModalHistoricoComprasShow(false);
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function ModalHistoricoCompras(props) {
    const [historicoComprasColumnsSetup] = useState([
      { field: 'data', header: 'Data', sort: false, dataHora: true },
      { field: 'participante.cnpj', header: 'CNPJ', sort: false },
      { field: 'participante.nomeFantasia', header: 'Fornecedor', sort: false },
      { field: 'precoCusto', header: 'Preço Custo', sort: false },
      { field: 'precoCustoReal', header: 'Preço Custo Real', sort: false },
      { field: 'precoVenda', header: 'Preço Venda', sort: false },
    ]);

    if (!props.show) {
      return null;
    }

    const headerTable = (title) => (
      <div className="table-header">
        {title}
      </div>
    );

    return (
      <Modal {...props} aria-labelledby="contained-modal-title-vcenter" dialogClassName="main-modal">
        <Modal.Body className="show-grid">
          <div className="row form-elo">
            <div className="col-12">
              <DataTable
                header={headerTable('Histórico de Compras')}
                value={historicoComprasList}
                size="small"
                stripedRows
                responsiveLayout="scroll"
                selectionMode="single"
                selectableRows
                dataKey="titulo"
                emptyMessage="Nenhum registro a ser exibido"
              >
                {fillColumns(historicoComprasColumnsSetup)}
              </DataTable>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleHideModalHistoricoCompras}
            type="button"
            className="btn btn-primary"
          >
            <i className="pi pi-times"></i>
            Fechar
          </button>
        </Modal.Footer>
      </Modal>
    );
  }

  const onPageLogEstoque = (event) => {
    setLazyParamsLogEstoque(event);

    getLogEstoque(event.page, event.rows);
  };

  const onPageLogEstoqueIndividual = (event) => {
    // const { rowData } = lazyParamsLogEstoqueIndividual;
    setLazyParamsLogEstoqueIndividual(event);

    getLogEstoqueIndividual(idProduto, cnpjEmpresa, event.page, event.rows);
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function ModalLogEstoque(props) {
    const [logEstoqueColumnsSetup] = useState([
      { field: 'data', header: 'Data', sort: false, dataHora: true },
      { field: 'qtdeDepositoAntes', header: 'Depósito Antes', sort: false },
      { field: 'movDeposito', header: 'Mov Depósito', sort: false },
      { field: 'qtdeDepositoDepois', header: 'Depósito Depois', sort: false },
      { field: 'qtdePrateleiraAntes', header: 'Prateleira Antes', sort: false },
      { field: 'movPrateleira', header: 'Mov Prateleira', sort: false },
      { field: 'qtdePrateleiraDepois', header: 'Prateleira Depois', sort: false },
      { field: 'idUsuario', header: 'Usuário', sort: false },
      { field: 'modulo', header: 'Módulo', sort: false },
      { field: 'documento', header: 'Documento', sort: false },
      { field: 'rotina', header: 'Rotina', sort: false },
    ]);

    if (!props.show) {
      return null;
    }

    const headerTable = (title) => (
      <div className="table-header">
        {title}
      </div>
    );

    return (
      <Modal {...props} aria-labelledby="contained-modal-title-vcenter" dialogClassName="main-modal">
        <Modal.Body className="show-grid">
          <div className="row form-elo">
            <div className="col-12">
              <DataTable
                id="logEstoque"
                header={headerTable('Log Estoque')}
                value={logEstoqueList}
                size="small"
                stripedRows
                responsiveLayout="scroll"
                selectionMode="single"
                selectableRows
                dataKey="id"
                paginator
                lazy
                first={lazyParamsLogEstoque.first}
                rows={lazyParamsLogEstoque.rows}
                totalRecords={logEstoqueTotalRecords}
                onPage={onPageLogEstoque}
                emptyMessage="Nenhum registro a ser exibido"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="{currentPage} de {totalPages}"
              >
                {fillColumns(logEstoqueColumnsSetup)}
              </DataTable>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleHideModalLogEstoque}
            type="button"
            className="btn btn-primary"
          >
            <i className="pi pi-times"></i>
            Fechar
          </button>
        </Modal.Footer>
      </Modal>
    );
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  function ModalLogEstoqueIndividual(props) {
    const [logEstoqueColumnsSetup] = useState([
      { field: 'data', header: 'Data', sort: false, dataHora: true },
      { field: 'qtdeDepositoAntes', header: 'Depósito Antes', sort: false },
      { field: 'movDeposito', header: 'Mov Depósito', sort: false },
      { field: 'qtdeDepositoDepois', header: 'Depósito Depois', sort: false },
      { field: 'qtdePrateleiraAntes', header: 'Prateleira Antes', sort: false },
      { field: 'movPrateleira', header: 'Mov Prateleira', sort: false },
      { field: 'qtdePrateleiraDepois', header: 'Prateleira Depois', sort: false },
      { field: 'idUsuario', header: 'Usuário', sort: false },
      { field: 'modulo', header: 'Módulo', sort: false },
      { field: 'documento', header: 'Documento', sort: false },
      { field: 'rotina', header: 'Rotina', sort: false },
    ]);

    if (!props.show) {
      return null;
    }

    const headerTable = (title) => (
      <div className="table-header">
        {title}
      </div>
    );

    return (
      <Modal {...props} aria-labelledby="contained-modal-title-vcenter" dialogClassName="main-modal">
        <Modal.Body className="show-grid">
          <div className="row form-elo">
            <div className="col-12">
              <DataTable
                id="logEstoque"
                header={headerTable('Log Estoque')}
                value={logEstoqueList}
                size="small"
                stripedRows
                responsiveLayout="scroll"
                selectionMode="single"
                selectableRows
                dataKey="id"
                paginator
                lazy
                first={lazyParamsLogEstoqueIndividual.first}
                rows={lazyParamsLogEstoqueIndividual.rows}
                totalRecords={logEstoqueIndividualTotalRecords}
                onPage={onPageLogEstoqueIndividual}
                emptyMessage="Nenhum registro a ser exibido"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="{currentPage} de {totalPages}"
              >
                {fillColumns(logEstoqueColumnsSetup)}
              </DataTable>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleHideModalLogEstoqueIndividual}
            type="button"
            className="btn btn-primary"
          >
            <i className="pi pi-times"></i>
            Fechar
          </button>
        </Modal.Footer>
      </Modal>
    );
  }

  async function verificarSeMultiEmpresa(idUsuario) {
    try {
      setIsLoading(true);

      const params = `/usuario/${idUsuario}`;

      const response = await axiosAdmin.get(params);

      const _listaEmpresas = get(response.data, 'listaEmpresas', []);

      const listDropdown = [];

      let cnpjPreenchido = false;
      let _cnpjEmpresaStore = get(store.getState().auth, 'cnpjEmpresa', '');

      if (_cnpjEmpresaStore !== null && _cnpjEmpresaStore !== '') {
        setCnpjEmpresa(_cnpjEmpresaStore);
        cnpjPreenchido = true;
      }

      const _cadastroSincronizado = get(response.data, 'cadastroSincronizado', true);

      setCadastroSincronizado(_cadastroSincronizado);

      if (_listaEmpresas.length > 1 && !_cadastroSincronizado) {
        listDropdown.push({ label: 'Todas', value: '', id: 0 });

        setCnpjEmpresa('');

        cnpjPreenchido = true;

        setColumnsSetup([
          { field: 'empresaNomeAbreviado', header: 'Empresa', sort: false },
          { field: 'registro', header: 'Registro', sort: true },
          { field: 'descricao', header: 'Descrição', sort: true },
          { field: 'unidadeMedida.sigla', header: 'Unid', sort: true },
          { field: 'grupo.codigo', header: 'Grupo', sort: true },
          { field: 'listaEstoques.qtdeDeposito', header: 'Depósito', sort: false },
          { field: 'listaEstoques.qtdePrateleira', header: 'Prateleira', sort: false },
          { field: 'precoCustoReal', header: 'Pr Custo Real', sort: false },
          { field: 'precoVenda', header: 'Preço Venda', sort: false },
        ]);
      }

      _listaEmpresas.forEach(element => {
        if (element.ativo) {
          if (!cnpjPreenchido) {
            setCnpjEmpresa(element.cnpj);
            _cnpjEmpresaStore = element.cnpj;
            cnpjPreenchido = true;
          }

          const nomeEmpresa = element.nomeFantasia ? element.nomeFantasia : element.razaoSocial;

          listDropdown.push({ label: nomeEmpresa, value: element.cnpj, id: element.id, ativo: element.ativo });
        }
      });

      // empresaCnpj recebido pelo childtoparent na criacao/edicao de nfe
      if (!isEmpty(empresaCnpj)) {
        _cnpjEmpresaStore = empresaCnpj;
        setCnpjEmpresa(empresaCnpj);
      }

      listDropdown.sort((a, b) => ((a.label > b.label) ? 1 : -1));

      setListaEmpresas(listDropdown);

      if (_listaEmpresas.length > 1 && _cadastroSincronizado) {
        setMultiEmpresa(true);
        setColumnsSetup([
          { field: 'registro', header: 'Registro', sort: true },
          { field: 'codigoEan', header: 'Código EAN', sort: true },
          { field: 'descricao', header: 'Descrição', sort: true },
          { field: 'unidadeMedida.sigla', header: 'Unid', sort: false },
          { field: 'grupo.codigo', header: 'Grupo', sort: false },
          { field: 'precoCustoReal', header: 'Pr Custo Real', sort: false },
          { field: 'precoVenda', header: 'Preço Venda', sort: false },
        ]);
      }

      getData('', _cnpjEmpresaStore, 0, lazyParams.rows);
    } catch (err) {
      if (mostrarMensagemErro) {
        displayErrors(err, dispatch, navigate, '/', '');
      }
      mostrarMensagemErro = false;
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    verificarSeMultiEmpresa(get(store.getState().auth.user, 'id', 0));

    getConfiguracaoSistema();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleFind = () => {
    const searchFilter = {
      findField,
    };

    const _lazyParams = { ...lazyParams, first: 0, page: 0 };

    setLazyParams(_lazyParams);

    getData(searchFilter, cnpjEmpresa, 0, lazyParams.rows);
  };

  const handleViewHistoricoCompras = (e) => {
    e.preventDefault();

    if (!selectedItens) {
      toast.warning('Por favor, selecione o registro para visualização do histórico de compras');
    } else {
      const registroProduto = get(selectedItens, 'registro', 0);

      if (registroProduto > 0) {
        getHistoricoCompras(cnpjEmpresa, registroProduto);
        setModalHistoricoComprasShow(true);
      } else {
        toast.warning('Produto selecionado não possui histórico de compras');
      }
    }
  };

  const handleViewLogEstoque = (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);

      const _lazyParamsLogEstoque = { ...lazyParamsLogEstoque, first: 0, page: 0 };

      setLazyParamsLogEstoque(_lazyParamsLogEstoque);

      if (!selectedItens) {
        toast.warning('Por favor, selecione o registro para visualização do histórico de compras');
      } else {
        // eslint-disable-next-line no-shadow
        const idProduto = get(selectedItens, 'id', 0);

        if (idProduto > 0) {
          getLogEstoque(0, lazyParamsLogEstoque.rows);
          setModalLogEstoqueShow(true);
        } else {
          toast.warning('Produto selecionado não possui histórico de compras');
        }
      }
    } catch (err) {
      //
    } finally {
      setIsLoading(false);
    }
  };

  const handleViewLogEstoqueIndividual = (rowData) => {
    try {
      setIsLoading(true);

      const _lazyParamsLogEstoqueIndividual = { ...lazyParamsLogEstoqueIndividual, first: 0, page: 0, rowData };

      setLazyParamsLogEstoqueIndividual(_lazyParamsLogEstoqueIndividual);

      if (!rowData) {
        toast.warning('Por favor, selecione o registro para visualização do log de estoque');
      } else {
        // eslint-disable-next-line no-shadow
        const idProduto = get(rowData, 'idProduto', 0);

        if (idProduto > 0) {
          setIdProduto(rowData.idProduto);
          setCnpjEmpresa(rowData.cnpjEmpresa);

          getLogEstoqueIndividual(rowData.idProduto, rowData.cnpjEmpresa, lazyParamsLogEstoqueIndividual.page);
          setModalLogEstoqueIndividualShow(true);
        } else {
          toast.warning('Produto selecionado não possui histórico de compras');
        }
      }
    } catch (err) {
      //
    } finally {
      setIsLoading(false);
    }
  };

  // eslint-disable-next-line consistent-return
  const handleRegister = () => {
    if (!permitirCadastrar) {
      toast.info('Usuário não autorizado a cadastrar.');
      return false;
    }

    const id = 0;

    setPayloadCadastro({ id, cnpjEmpresa });

    setShowCadastro(true);
  };

  // eslint-disable-next-line consistent-return
  const handleEdit = (e) => {
    e.preventDefault();

    if (!permitirEditar) {
      toast.info('Usuário não autorizado a editar.');
      return false;
    }

    const id = get(selectedItens, 'id', 0);

    if (id > 0) {
      setPayloadCadastro({ id, cnpjEmpresa });
      setShowCadastro(true);
    } else {
      toast.warning('Por favor, selecione o registro para edição');
    }
  };

  // eslint-disable-next-line consistent-return
  const handleDelete = (e) => {
    e.preventDefault();

    if (!permitirExcluir) {
      toast.info('Usuário não autorizado a excluir.');
      return false;
    }

    const id = get(selectedItens, 'id', 0);

    if (id > 0) {
      setShowConfirmDialog(true);
    } else {
      toast.warning('Por favor, selecione o registro para exclusão');
    }
  };

  const cancelDelete = () => {
    setShowConfirmDialog(false);
  };

  const confirmDelete = async () => {
    setShowConfirmDialog(false);

    try {
      setIsLoading(true);

      const idExclusao = get(selectedItens, 'id', 0);

      if (idExclusao > 0) {
        await axiosAdmin.delete(`/produto/${idExclusao}`);

        const _itens = dataTableList.filter(val => val.id !== idExclusao);
        setDataTableList(_itens);

        toast.success('Registro excluído com sucesso');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível excluir o registro');
    } finally {
      setIsLoading(false);
    }
  };

  const confirmDialogFooter = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDelete} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDelete} />
    </div>
  );

  const onSort = (event) => {
    setSortField(event.sortField);
    setSortOrder(event.sortOrder);

    setLazyParams(event);

    setDataTableList(orderBy(dataTableList, [sortField], [sortOrder]));
  };

  function showDialogAlterarEstoque(rowData) {
    setShowAlteraEstoque(true);
    setRowDataAlterarEstoque(rowData);
    setAlteraEstoqueDeposito(get(rowData, 'qtdeDeposito', 0));
    setAlteraEstoquePrateleira(get(rowData, 'qtdePrateleira', 0));
  }

  const actionBodyAlterarEstoque = (rowData) => {
    if (habilitarAlteracaoEstoqueFilial) {
      return (
        <>
          <Button
            icon="pi pi-pencil"
            title="Alterar estoque"
            className="p-button-rounded p-button-success"
            onClick={() => showDialogAlterarEstoque(rowData)}
          />
          <Button
            icon="pi pi-history"
            title="Log Estoque"
            className="p-button-rounded p-button-info"
            onClick={() => handleViewLogEstoqueIndividual(rowData)}
          />
        </>
      );
    }

    return (null);
  };

  useEffect(() => {
    const operadorGrupoAcesso = get(store.getState().auth.user, 'operadorGrupoAcesso', null);

    if (operadorGrupoAcesso != null) {
      let listaGrupoAcessoBody = [];
      listaGrupoAcessoBody = operadorGrupoAcesso.listaGrupoAcessoBody;
      const permissaoAlterarEstoque = listaGrupoAcessoBody.filter(el => el.modulo === 'AlterarEstoqueProduto');
      const permissaoCadastrar = listaGrupoAcessoBody.filter(el => el.modulo === 'CadastrarProduto');
      const permissaoEditar = listaGrupoAcessoBody.filter(el => el.modulo === 'EditarProduto');
      const permissaoExcluir = listaGrupoAcessoBody.filter(el => el.modulo === 'ExcluirProduto');

      if (permissaoAlterarEstoque.length <= 0) {
        setPermitirAlteracaoEstoque(false);
      }

      if (permissaoCadastrar.length <= 0) {
        setPermitirCadastrar(false);
      }

      if (permissaoEditar.length <= 0) {
        setPermitirEditar(false);
      }

      if (permissaoExcluir.length <= 0) {
        setPermitirExcluir(false);
      }
    }
  }, []);

  // eslint-disable-next-line consistent-return
  async function handleSalvarEstoque() {
    try {
      setIsLoading(true);

      if (!permitirAlteracaoEstoque) {
        toast.info('Usuário não autorizado a alterar estoque.');
        return false;
      }

      const idUsuario = get(store.getState().auth.user, 'id', 0);
      const id = get(rowDataAlterarEstoque, 'id', 0);

      if (id > 0) {
        await axiosAdmin.put(`/produto/atualizaestoque/${id}?idUsuario=${idUsuario}&qtdeDeposito=${alteraEstoqueDeposito}&qtdePrateleira=${alteraEstoquePrateleira}`);

        toast.success('Alteração de estoque enviada! Aguarde a atualização no servidor central e execute uma nova consulta.');

        setDataTableList([]);
        setShowAlteraEstoque(false);
      } else {
        toast.error('Não foi possível atualizar o estoque no servidor.');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar o registro');
    } finally {
      setIsLoading(false);
    }
  }

  const rowExpansionTemplate = (data) => (
    <div>
      <DataTable value={data.listaEstoques} responsiveLayout="scroll">
        <Column field="nomeEmpresa" header="Empresa"></Column>
        <Column field="qtdeDeposito" header="Qtde Depósito"></Column>
        <Column field="qtdePrateleira" header="Qtde Prateleira"></Column>
        <Column body={actionBodyAlterarEstoque} exportable={false}></Column>
      </DataTable>
    </div>
  );

  function fillExpander() {
    const _return = multiEmpresa ? <Column expander style={{ width: '3em' }} /> : null;

    return _return;
  }

  async function licencaValida(empresaId) {
    // eslint-disable-next-line consistent-return
    const retorno = await axiosAdmin.get(`/empresa/verificardatalicenca/${empresaId}`);
    return retorno.data;
  }

  // eslint-disable-next-line consistent-return
  async function handleCnpjEmpresa(e) {
    let empresaId = 0;
    // eslint-disable-next-line consistent-return
    listaEmpresas.forEach(element => {
      if (element.value === e.target.value) {
        // eslint-disable-next-line no-unused-expressions
        empresaId = element.id;
      }
    });

    const isLicencaValida = await licencaValida(empresaId);
    if (!isLicencaValida) {
      toast.info('Cliente com licença expirada. Efetuar pagamento mensalidade.');
      // navigate('/pagamento');
      return false;
    }

    setCnpjEmpresa(e.target.value);

    dispatch(actions.cnpjEmpresaChange({ cnpjEmpresa: e.target.value }));

    setDataTableList([]);

    getData('', e.target.value, 0, lazyParams.rows);
  }

  function selecaoEmpresa() {
    if (!cadastroSincronizado && listaEmpresas.length > 1) {
      return (
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Empresa
              </Input100px>
              <Dropdown
                options={listaEmpresas}
                value={cnpjEmpresa}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => handleCnpjEmpresa(e)}
              />
            </div>
          </div>
        </div>
      );
    }
    return '';
  }

  const onPage = (event) => {
    setLazyParams(event);

    const searchFilter = {
      findField,
    };

    getData(searchFilter, cnpjEmpresa, event.page, event.rows);
  };

  const onHideCadastro = () => {
    setShowCadastro(false);
  };

  const onHideAlteraEstoque = () => {
    setShowAlteraEstoque(false);
  };

  const childToParentConsulta = (payloadDataTableList, id) => {
    if (payloadDataTableList) {
      if (id > 0) {
        const index = dataTableList.map(e => e.id).indexOf(id);

        if (index >= 0) {
          dataTableList[index] = payloadDataTableList;
        }
      } else {
        const _dataTableList = [];
        _dataTableList.push(payloadDataTableList);

        setDataTableList(_dataTableList);

        const _lazyParams = { ...lazyParams, first: 0, page: 0 };
        setLazyParams(_lazyParams);
        setTotalRecords(1);
      }
    }

    setShowCadastro(false);
  };

  function renderBotaoSelect() {
    if (!menuMode) {
      return (
        <button
          type="button"
          title="Selecionar"
          className="btn btn-info"
          style={{ borderRadius: '15px' }}
          onClick={() => childToParent(selectedItens)}
        >
          <i className="pi pi-check"></i>
        </button>
      );
    }
    return '';
  }

  const handleFocus = (event) => event.target.select();

  return (
    <>
      <Loading isLoading={isLoading} />

      <Dialog
        header=""
        closeOnEscape={false}
        visible={showCadastro}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastro()}
        className="dialog-cadastro"
      >
        <CadastroProduto
          payloadCadastro={payloadCadastro}
          childToParentConsulta={childToParentConsulta}
        />
      </Dialog>

      <Dialog
        header="Alteração de Estoque"
        visible={showAlteraEstoque}
        style={{ width: '98vw' }}
        onHide={() => onHideAlteraEstoque()}
      >
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Qtde Depósito
              </Input155px>
              <InputNumber
                autoFocus
                mode="decimal"
                inputId="minmaxfraction"
                minFractionDigits={3}
                maxFractionDigits={3}
                value={alteraEstoqueDeposito}
                onValueChange={e => setAlteraEstoqueDeposito(e.target.value)}
                onFocus={handleFocus}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Qtde Prateleira
              </Input155px>
              <InputNumber
                mode="decimal"
                inputId="minmaxfraction"
                minFractionDigits={3}
                maxFractionDigits={3}
                value={alteraEstoquePrateleira}
                onValueChange={e => setAlteraEstoquePrateleira(e.target.value)}
                onFocus={handleFocus}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button
              type="button"
              className="btn btn-success"
              onClick={handleSalvarEstoque}
            >
              <i className="pi pi-check"></i>
              Alterar
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={() => setShowAlteraEstoque(false)}
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Dialog>

      <ModalHistoricoCompras
        show={modalHistoricoComprasShow}
        onHide={handleHideModalHistoricoCompras}
        dialogClassName="modal-90w"
      />

      <ModalLogEstoque
        show={modalLogEstoqueShow}
        onHide={handleHideModalLogEstoque}
        dialogClassName="modal-90w"
      />

      <ModalLogEstoqueIndividual
        show={modalLogEstoqueIndividualShow}
        onHide={handleHideModalLogEstoqueIndividual}
        dialogClassName="modal-90w"
      />

      <Card>
        <div>
          <div className="card mb-3">
            <h6 className="card-header" style={{ textAlign: 'center' }}>Produtos</h6>
          </div>
          {selecaoEmpresa()}
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Buscar por:
                </Input100px>
                <InputCampoBusca155px className="p-inputgroup-addon">
                  {columnsSetup.find(element => element.field === sortField).header}
                </InputCampoBusca155px>
                <InputText
                  maxLength={250}
                  value={findField}
                  onChange={e => setFindField(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-4">
              <button
                type="button"
                title="Buscar"
                className="btn btn-success"
                style={{ borderRadius: '15px' }}
                onClick={handleFind}
              >
                <i className="pi pi-search"></i>
              </button>
              <button
                disabled={desabilitarCadastroProduto || somenteLeitura}
                type="button"
                title="Cadastrar"
                className="btn btn-primary"
                style={{ borderRadius: '15px' }}
                onClick={handleRegister}
              >
                <i className="pi pi-plus"></i>
              </button>
              <button
                disabled={somenteLeitura}
                type="button"
                title="Editar"
                className="btn btn-warning"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleEdit(e)}
              >
                <i className="pi pi-pencil"></i>
              </button>
              <button
                disabled={desabilitarCadastroProduto || somenteLeitura}
                type="button"
                title="Excluir"
                className="btn btn-danger"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleDelete(e)}
              >
                <i className="pi pi-trash"></i>
              </button>
              <button
                disabled={somenteLeitura}
                type="button"
                title="Histórico de Compras"
                className="btn btn-secondary"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleViewHistoricoCompras(e)}
              >
                <i className="pi pi-book"></i>
              </button>
              <button
                disabled={somenteLeitura}
                type="button"
                title="Log Estoque"
                className="btn btn-dark"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleViewLogEstoque(e)}
              >
                <i className="pi pi-history"></i>
              </button>
              {renderBotaoSelect()}
            </div>
            <div className="col-lg-2">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Inativos
                </Input100px>
                <ToggleButton
                  onLabel="Sim"
                  offLabel="Não"
                  onIcon="pi pi-check"
                  offIcon="pi pi-times"
                  checked={inativos}
                  onChange={(e) => setInativos(e.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <br />

        <div className="card">
          <DataTable
            id="produtos"
            value={dataTableList}
            expandedRows={expandedRows}
            onRowToggle={(e) => setExpandedRows(e.data)}
            responsiveLayout="scroll"
            rowExpansionTemplate={rowExpansionTemplate}
            dataKey="id"
            size="small"
            stripedRows
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={onSort}
            selectionMode="single"
            selection={selectedItens}
            loading={isLoading}
            onSelectionChange={e => setSelectedItens(e.value)}
            selectableRows
            paginator
            lazy
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            onPage={onPage}
            emptyMessage="Nenhum registro a ser exibido"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{currentPage} de {totalPages}"
            rowsPerPageOptions={[10, 25, 50]}
          >
            {fillExpander()}
            {fillColumns(columnsSetup)}
          </DataTable>
        </div>

        <Dialog
          header="Confirmação"
          visible={showConfirmDialog}
          style={{ width: '50vw' }}
          footer={confirmDialogFooter}
          onHide={() => setShowConfirmDialog(false)}
        >
          <p>
            Excluir o registro?
          </p>
        </Dialog>
      </Card>
    </>
  );
}
