import React, { useState, useEffect } from 'react';

import 'react-toastify/dist/ReactToastify.css';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { get, isEmpty } from 'lodash';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { ToggleButton } from 'primereact/togglebutton';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { displayErrors, formataDataMoment, converteData } from '../../../../util/diversos';
import { Input155px, Input200px } from '../../../../styles/inputGroup';

import store from '../../../../store/store';
import Card from '../../../../components/card';
import Loading from '../../../loading';
import axiosAdmin from '../../../../services/axiosAdmin';

import ConsultaParticipante from '../../participante/consultaParticipante';

export default function CadastroOrdemServicoAparelho({ payloadCadastro, childToParentConsulta }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [idUsuario, setIdUsuario] = useState(get(store.getState().auth.user, 'id', null));

  const id = get(payloadCadastro, 'id', 0);
  const tipoUsuario = get(store.getState().auth.user, 'tipoUsuario', null);

  // eslint-disable-next-line no-unused-vars
  const [idEmpresa, setIdEmpresa] = useState(null);
  const [cnpjEmpresa, setCnpjEmpresa] = useState(get(payloadCadastro, 'cnpj', ''));
  // eslint-disable-next-line no-unused-vars
  const [listaEmpresas, setListaEmpresas] = useState([]);

  const [numero, setNumero] = useState(1);
  const [dataAbertura, setDataAbertura] = useState(new Date());
  const [dataEntrega, setDataEntrega] = useState(null);
  const [valorOrcamento, setValorOrcamento] = useState(0);
  const [idCliente, setIdCliente] = useState(null);
  const [nomeCliente, setNomeCliente] = useState('');
  const [telefoneCliente, setTelefoneCliente] = useState('');
  const [tipo, setTipo] = useState('SERVICO');
  const [marca, setMarca] = useState('');
  const [modelo, setModelo] = useState('');
  const [imei, setImei] = useState('');
  const [garantiaDias] = useState('90');
  const [slotChip, setSlotChip] = useState(false);
  const [condicaoTeste, setCondicaoTeste] = useState(false);
  const [aparelhoLiga, setAparelhoLiga] = useState(true);
  const [aparelhoMolhado, setAparelhoMolhado] = useState(false);
  const [aparelhoCarrega, setAparelhoCarrega] = useState(true);
  const [queixas, setQueixas] = useState('');
  const [avaliacaoTecnica, setAvaliacaoTecnica] = useState('');
  const [valorFinal, setValorFinal] = useState(0);

  const [showConsultaParticipante, setShowConsultaParticipante] = useState(false);

  const [listaTipo] = useState([
    { label: 'Serviço', value: 'SERVICO' },
    { label: 'Garantia', value: 'GARANTIA' },
  ]);

  useEffect(() => {
    if (!id) return;

    async function getData() {
      try {
        setIsLoading(true);

        const { data } = await axiosAdmin.get(`/ticket/${id}`);

        const _dataAbertura = converteData(data.dataAbertura, true);
        const _dataEntrega = converteData(data.dataEntrega, true);

        setIdUsuario(data.idUsuario);
        setDataAbertura(_dataAbertura);
        setDataEntrega(_dataEntrega);
        setValorOrcamento(data.valorOrcamento);
        setIdCliente(data.idCliente);
        setNomeCliente(data.nomeCliente);
        setTelefoneCliente(data.telefoneCliente);
        setTipo(data.tipo);
        setMarca(data.marca);
        setModelo(data.modelo);
        setImei(data.imei);
        setSlotChip(data.slotChip);
        setCondicaoTeste(data.condicaoTeste);
        setAparelhoLiga(!data.aparelhoLiga);
        setAparelhoMolhado(data.aparelhoMolhado);
        setAparelhoCarrega(data.aparelhoCarrega);
        setQueixas(data.queixas.trim());
        setAvaliacaoTecnica(data.avaliacaoTecnica.trim());
        setValorFinal(data.valorFinal);

        if (tipoUsuario === 'EMPRESA') {
          setCnpjEmpresa(get(data.empresa, 'cnpj', null));
        } else {
          setIdEmpresa(get(data.empresa, 'id', null));
        }
      } catch (err) {
        displayErrors(err, dispatch, navigate, '/', '');
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, navigate]);

  useEffect(() => {
    async function verificarSeMultiEmpresa() {
      if (tipoUsuario === 'EMPRESA') {
        try {
          setIsLoading(true);

          const params = `/usuario/${idUsuario}`;

          const response = await axiosAdmin.get(params);

          const _listaEmpresas = get(response.data, 'listaEmpresas', []);

          const listDropdown = [];

          let cnpjPreenchido = false;
          let _cnpjEmpresaStore = get(store.getState().auth, 'cnpjEmpresa', '');

          if (_cnpjEmpresaStore !== null && _cnpjEmpresaStore !== '') {
            setCnpjEmpresa(_cnpjEmpresaStore);
            cnpjPreenchido = true;
          }

          _listaEmpresas.forEach(element => {
            if (!cnpjPreenchido) {
              setCnpjEmpresa(element.cnpj);
              setIdEmpresa(element.id);
              _cnpjEmpresaStore = element.cnpj;
              cnpjPreenchido = true;
            }

            listDropdown.push(
              {
                label: element.razaoSocial,
                value: element.cnpj,
                id: element.id,
              });
          });

          // empresaCnpj recebido pelo childtoparent na criacao/edicao de nfe
          if (!isEmpty(cnpjEmpresa)) {
            setCnpjEmpresa(cnpjEmpresa);
          }

          listDropdown.sort((a, b) => ((a.label > b.label) ? 1 : -1));

          setListaEmpresas(listDropdown);
        } catch (err) {
          displayErrors(err, dispatch, navigate, '/', '');
        } finally {
          setIsLoading(false);
        }
      }
    }

    verificarSeMultiEmpresa(get(store.getState().auth.user, 'id', 0));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  // eslint-disable-next-line consistent-return
  async function salvarRegistro() {
    try {
      setIsLoading(true);

      let _dataAbertura = null;
      if (dataAbertura) {
        _dataAbertura = formataDataMoment(dataAbertura, '-', true);
      }

      let _dataEntrega = null;
      if (dataEntrega) {
        _dataEntrega = formataDataMoment(dataEntrega, '-', true);
      }

      const shippingData = {
        idUsuario,
        cnpjEmpresa,
        numero,
        data: _dataAbertura,
        dataEntrega: _dataEntrega,
        valorOrcamento,
        idCliente,
        nomeCliente,
        telefoneCliente,
        tipo,
        marca,
        modelo,
        imei,
        garantiaDias,
        slotChip,
        condicaoTeste,
        aparelhoLiga,
        aparelhoMolhado,
        aparelhoCarrega,
        queixas,
        avaliacaoTecnica,
        valorFinal,
      };

      console.log('shippingdata: ', shippingData);

      let payloadDataTableList = {};

      if (id > 0) {
        payloadDataTableList = await axiosAdmin.put(`/osaparelho/${id}`, shippingData);
        toast.success('OS Aparelho atualizada com sucesso');
      } else {
        payloadDataTableList = await axiosAdmin.post('/osaparelho', shippingData);
        toast.success('OS Aparelho cadastrada com sucesso');
      }

      childToParentConsulta(payloadDataTableList, id);
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível atualizar o registro');
    } finally {
      setIsLoading(false);
    }
  }

  const handleCancel = () => {
    childToParentConsulta();
  };

  // eslint-disable-next-line consistent-return
  const handleSubmit = (e) => {
    e.preventDefault();

    let formErros = false;

    if (valorOrcamento <= 0) {
      toast.info('Valor do orçamento deve ser informado');
      return false;
    }

    if (nomeCliente.length < 1) {
      toast.info('Nome do Cliente deve ser informado');
      return false;
    }

    if (telefoneCliente.length < 8) {
      toast.info('Telefone deve ser informado');
      return false;
    }

    if (tipo === 'GARANTIA' && garantiaDias >= 90) {
      toast.info('Dias Garantia não pode ser maior ou igual a 90 dias');
      return false;
    }

    if (marca.length === 0) {
      toast.info('Marca deve ser informada');
      return false;
    }

    if (modelo.length === 0) {
      toast.info('Modelo deve ser informado');
      return false;
    }

    if (queixas.length < 3 || queixas.length > 500) {
      formErros = true;
      toast.error('Queixas devem ter entre 3 a 499 caracteres');
    }

    // eslint-disable-next-line consistent-return
    if (formErros) return;

    salvarRegistro();
  };

  const onHideConsultaParticipante = () => {
    setShowConsultaParticipante(false);
  };

  const childToParentParticipante = (e) => {
    const idElement = get(e, 'id', 0);

    if (idElement > 0) {
      if (e.id) {
        setIdCliente(e.id);
      }
      if (e.razaoSocial) {
        setNomeCliente(e.razaoSocial);
      }
      if (e.telefone_1) {
        setTelefoneCliente(e.telefone_1);
      }
      setShowConsultaParticipante(false);
    } else {
      toast.warning('Por favor, selecione o registro');
    }
  };

  const titulo = () => (id ? 'Ticket - Alteração' : 'Ticket - Inclusão');

  return (
    <div>
      <Loading isLoading={isLoading} />

      <Dialog
        header="Consulta de Empresas"
        visible={showConsultaParticipante}
        style={{ width: '98vw' }}
        onHide={() => onHideConsultaParticipante()}
      >
        <ConsultaParticipante menuMode={false} childToParent={childToParentParticipante} somenteLeitura="true" />
      </Dialog>

      <Card>
        <div className="card mb-3">
          <h6 className="card-header" style={{ textAlign: 'center' }}>{titulo()}</h6>
        </div>
        <div className="row form-elo">
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Número*
              </Input155px>
              <InputNumber
                autoFocus
                onFocus={(e) => e.target.select()}
                maxFractionDigits={0}
                value={numero}
                onValueChange={e => setNumero(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Data Abertura
              </Input155px>
              <Calendar
                disabled
                id="mask"
                showIcon
                showTime
                showSeconds
                hourFormat="24"
                mask="99/99/9999 99:99:99"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={dataAbertura}
                onChange={(e) => setDataAbertura(e.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Data Entrega
              </Input155px>
              <Calendar
                disabled
                id="mask"
                showIcon
                showTime
                showSeconds
                hourFormat="24"
                mask="99/99/9999 99:99:99"
                dateFormat="dd/mm/yy"
                showButtonBar
                showOnFocus={false}
                locale="pt-BR"
                value={dataEntrega}
                onChange={(e) => setDataEntrega(e.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Valor Orcamento
              </Input155px>
              <InputNumber
                onFocus={(e) => e.target.select()}
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={valorOrcamento}
                onValueChange={e => setValorOrcamento(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-6">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Cliente*
              </Input155px>
              <InputText
                onFocus={(e) => e.target.select()}
                maxLength={40}
                value={nomeCliente}
                onChange={e => setNomeCliente(e.target.value)}
              />
              <Button
                icon="pi pi-search"
                className="p-button-warning"
                onClick={() => setShowConsultaParticipante(true)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Telefone*
              </Input155px>
              <InputText
                onFocus={(e) => e.target.select()}
                id="inputTelefone"
                maxLength={20}
                value={telefoneCliente}
                onChange={e => setTelefoneCliente(e.target.value)}
              />
            </div>
          </div>
          <div className="col-3">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Tipo
              </Input155px>
              <Dropdown
                options={listaTipo}
                value={tipo}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={(e) => setTipo(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Marca*
              </Input155px>
              <InputText
                onFocus={(e) => e.target.select()}
                id="inputMarca"
                maxLength={50}
                value={marca}
                onChange={e => setMarca(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Modelo*
              </Input155px>
              <InputText
                onFocus={(e) => e.target.select()}
                id="inputModelo"
                maxLength={50}
                value={modelo}
                onChange={e => setModelo(e.target.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                IMEI
              </Input155px>
              <InputText
                onFocus={(e) => e.target.select()}
                id="inputImei"
                maxLength={50}
                value={imei}
                onChange={e => setImei(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Slot Chip
              </Input200px>
              <ToggleButton
                onLabel="Sim"
                offLabel="Não"
                onIcon="pi pi-check"
                offIcon="pi pi-times"
                checked={slotChip}
                onChange={(e) => setSlotChip(e.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Condição de Teste
              </Input200px>
              <ToggleButton
                onLabel="Sim"
                offLabel="Não"
                onIcon="pi pi-check"
                offIcon="pi pi-times"
                checked={condicaoTeste}
                onChange={(e) => setCondicaoTeste(e.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Aparelho Molhado
              </Input200px>
              <ToggleButton
                onLabel="Sim"
                offLabel="Não"
                onIcon="pi pi-check"
                offIcon="pi pi-times"
                checked={aparelhoMolhado}
                onChange={(e) => setAparelhoMolhado(e.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Aparelho Liga
              </Input200px>
              <ToggleButton
                onLabel="Sim"
                offLabel="Não"
                onIcon="pi pi-check"
                offIcon="pi pi-times"
                checked={aparelhoLiga}
                onChange={(e) => setAparelhoLiga(e.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input200px className="p-inputgroup-addon">
                Aparelho Carrega
              </Input200px>
              <ToggleButton
                onLabel="Sim"
                offLabel="Não"
                onIcon="pi pi-check"
                offIcon="pi pi-times"
                checked={aparelhoCarrega}
                onChange={(e) => setAparelhoCarrega(e.value)}
              />
            </div>
          </div>
          <div className="col-4">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Valor Final
              </Input155px>
              <InputNumber
                onFocus={(e) => e.target.select()}
                mode="currency"
                inputId="currency-br"
                currency="BRL"
                locale="pt-BR"
                minFractionDigits={2}
                maxFractionDigits={2}
                value={valorFinal}
                onValueChange={e => setValorFinal(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Queixas*
              </Input155px>
              <InputTextarea
                rows={2}
                value={queixas}
                onChange={e => setQueixas(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input155px className="p-inputgroup-addon">
                Avaliação Técnica
              </Input155px>
              <InputTextarea
                rows={2}
                value={avaliacaoTecnica}
                onChange={e => setAvaliacaoTecnica(e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <button
              onClick={handleSubmit}
              type="button"
              className="btn btn-success"
            >
              <i className="pi pi-check"></i>
              Salvar
            </button>
            <button
              onClick={handleCancel}
              type="button"
              className="btn btn-danger"
            >
              <i className="pi pi-times"></i>
              Cancelar
            </button>
          </div>
        </div>
      </Card>
    </div>
  );
}
