/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { Dropdown } from 'primereact/dropdown';
import { get, orderBy, isEmpty } from 'lodash';
import { addLocale } from 'primereact/api';
import { toast } from 'react-toastify';

import dateFormat from 'dateformat';

import * as actions from '../../../../store/modules/auth/actions';

import 'react-toastify/dist/ReactToastify.css';

import { displayErrors } from '../../../../util/diversos';
import { fillColumns } from '../../../../components/table';
import { Input100px, Input155px, InputCampoBusca155px } from '../../../../styles/inputGroup';

// eslint-disable-next-line import/no-cycle
import store from '../../../../store/store';
import axiosAdmin from '../../../../services/axiosAdmin';
import Card from '../../../../components/card';
import Loading from '../../../loading';

import CadastroOrdemServicoAparelho from './cadastroOrdemServicoAparelho';

export default function ConsultaOrdemServicoAparelho({ empresaCnpj, somenteLeitura = false }) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [permitirFechar, setPermitirFechar] = useState(true);
  const [permitirImprimir, setPermitirImprimir] = useState(true);
  const [permitirCadastrar, setPermitirCadastrar] = useState(true);
  const [permitirEditar, setPermitirEditar] = useState(true);
  const [permitirExcluir, setPermitirExcluir] = useState(true);

  const [findDataInicial, setFindDataInicial] = useState(new Date('01-01-2000'));
  const [findDataFinal, setFindDataFinal] = useState(new Date());
  const [totalRecords, setTotalRecords] = useState(0);
  const [dataTableList, setDataTableList] = useState([]);
  const [selectedItens, setSelectedItens] = useState(null);
  const [findField, setFindField] = useState('');
  const [sortField, setSortField] = useState('nomeCliente');
  const [sortOrder, setSortOrder] = useState(null);
  const [expandedRows, setExpandedRows] = useState(null);
  const [multiEmpresa, setMultiEmpresa] = useState(false);
  const [cadastroSincronizado, setCadastroSincronizado] = useState(true);
  const [listaEmpresas, setListaEmpresas] = useState([]);
  const [cnpjEmpresa, setCnpjEmpresa] = useState('');

  const [showCadastro, setShowCadastro] = useState(false);
  const [payloadCadastro, setPayloadCadastro] = useState({});

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showFecharConfirmDialog, setShowFecharConfirmDialog] = useState(false);

  const [columnsSetup, setColumnsSetup] = useState([
    { field: 'registro', header: 'Registro', sort: true },
    { field: 'data', header: 'Data', sort: true },
    { field: 'nomeCliente', header: 'Cliente', sort: true },
    { field: 'listaEstoques.qtdeDeposito', header: 'Depósito', sort: false },
    { field: 'listaEstoques.qtdePrateleira', header: 'Prateleira', sort: false },
    { field: 'precoVenda', header: 'Preço Venda', sort: false },
  ]);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 1,
    sortField: null,
    sortOrder: null,
    filters: null,
  });

  addLocale('pt-BR', {
    firstDayOfWeek: 0,
    dayNames: ['domingo', 'segunda', 'terça', 'quarta', 'quinta', 'sexta', 'sábado'],
    dayNamesShort: ['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sáb'],
    dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
    monthNames: ['janeiro', 'fevereiro', 'março', 'abril', 'maio', 'junho', 'julho', 'agosto', 'setembro', 'outubro', 'novembro', 'dezembro'],
    monthNamesShort: ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'],
    today: 'hoje',
    clear: 'Limpar',
  });

  let mostrarMensagemErro = true;

  async function getData(searchFilter, filtrarCnpjEmpresa, page = 0, size = 10) {
    try {
      setIsLoading(true);

      const idUsuario = get(store.getState().auth.user, 'id', 0);

      let params = `/osaparelho/search?idUsuario=${idUsuario}`;

      if (searchFilter && searchFilter.findDataInicial !== '') {
        const _dataInicial = `${dateFormat(searchFilter.findDataInicial, 'dd-mm-yyyy')} 00:00:00`;
        const _dataFinal = `${dateFormat(searchFilter.findDataFinal, 'dd-mm-yyyy')} 23:59:59`;

        params = `${params}&dataInicial=${_dataInicial}&dataFinal=${_dataFinal}`;
      }

      if (filtrarCnpjEmpresa !== '') {
        params = `${params}&cnpjEmpresa=${filtrarCnpjEmpresa}`;
      }

      if (searchFilter && searchFilter.findField !== '') {
        params = `${params}&${sortField}=${searchFilter.findField}`;
      }

      params = `${params}&page=${page}&size=${size}`;

      const response = await axiosAdmin.get(params);

      // console.log('params: ', params);

      setDataTableList(response.data.content);
      setTotalRecords(response.data.totalElements);
    } catch (err) {
      if (mostrarMensagemErro) {
        displayErrors(err, dispatch, navigate, '/', '');
      }
      mostrarMensagemErro = false;
    } finally {
      setIsLoading(false);
    }
  }

  async function verificarSeMultiEmpresa(idUsuario) {
    try {
      setIsLoading(true);

      const params = `/usuario/${idUsuario}`;

      const response = await axiosAdmin.get(params);

      const _listaEmpresas = get(response.data, 'listaEmpresas', []);

      const listDropdown = [];

      let cnpjPreenchido = false;
      let _cnpjEmpresaStore = get(store.getState().auth, 'cnpjEmpresa', '');

      if (_cnpjEmpresaStore !== null && _cnpjEmpresaStore !== '') {
        setCnpjEmpresa(_cnpjEmpresaStore);
        cnpjPreenchido = true;
      }

      const _cadastroSincronizado = get(response.data, 'cadastroSincronizado', true);

      setCadastroSincronizado(_cadastroSincronizado);

      if (_listaEmpresas.length > 1 && !_cadastroSincronizado) {
        listDropdown.push({ label: 'Todas', value: '' });

        setCnpjEmpresa('');

        cnpjPreenchido = true;

        setColumnsSetup([
          { field: 'empresaNomeAbreviado', header: 'Empresa', sort: false },
          { field: 'registro', header: 'Registro', sort: true },
          { field: 'data', header: 'Data', sort: true },
          { field: 'nomeCliente', header: 'Cliente', sort: true },
          { field: 'listaEstoques.qtdeDeposito', header: 'Depósito', sort: false },
          { field: 'listaEstoques.qtdePrateleira', header: 'Prateleira', sort: false },
          { field: 'precoVenda', header: 'Preço Venda', sort: false },
        ]);
      }

      _listaEmpresas.forEach(element => {
        if (!cnpjPreenchido) {
          setCnpjEmpresa(element.cnpj);
          _cnpjEmpresaStore = element.cnpj;
          cnpjPreenchido = true;
        }

        const nomeEmpresa = element.nomeFantasia ? element.nomeFantasia : element.razaoSocial;

        listDropdown.push({ label: nomeEmpresa, value: element.cnpj });
      });

      // empresaCnpj recebido pelo childtoparent na criacao/edicao de nfe
      if (!isEmpty(empresaCnpj)) {
        _cnpjEmpresaStore = empresaCnpj;
        setCnpjEmpresa(empresaCnpj);
      }

      listDropdown.sort((a, b) => ((a.label > b.label) ? 1 : -1));

      setListaEmpresas(listDropdown);

      if (_listaEmpresas.length > 1 && _cadastroSincronizado) {
        setMultiEmpresa(true);
        setColumnsSetup([
          { field: 'registro', header: 'Registro', sort: true },
          { field: 'codigoEan', header: 'Código EAN', sort: true },
          { field: 'data', header: 'Data', sort: true },
          { field: 'nomeCliente', header: 'Cliente', sort: true },
          { field: 'precoVenda', header: 'Preço Venda', sort: false },
        ]);
      }
    } catch (err) {
      if (mostrarMensagemErro) {
        displayErrors(err, dispatch, navigate, '/', '');
      }
      mostrarMensagemErro = false;
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    verificarSeMultiEmpresa(get(store.getState().auth.user, 'id', 0));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const operadorGrupoAcesso = get(store.getState().auth.user, 'operadorGrupoAcesso', null);

    if (operadorGrupoAcesso != null) {
      let listaGrupoAcessoBody = [];
      listaGrupoAcessoBody = operadorGrupoAcesso.listaGrupoAcessoBody;
      const permissaoFechar = listaGrupoAcessoBody.filter(el => el.modulo === 'FecharOrdemServicoAparelho');
      const permissaoImprimir = listaGrupoAcessoBody.filter(el => el.modulo === 'ImprimirOrdemServicoAparelho');
      const permissaoCadastrar = listaGrupoAcessoBody.filter(el => el.modulo === 'CadastrarOrdemServicoAparelho');
      const permissaoEditar = listaGrupoAcessoBody.filter(el => el.modulo === 'EditarOrdemServicoAparelho');
      const permissaoExcluir = listaGrupoAcessoBody.filter(el => el.modulo === 'ExcluirOrdemServicoAparelho');

      if (permissaoFechar.length <= 0) {
        setPermitirFechar(false);
      }

      if (permissaoCadastrar.length <= 0) {
        setPermitirCadastrar(false);
      }

      if (permissaoEditar.length <= 0) {
        setPermitirEditar(false);
      }

      if (permissaoExcluir.length <= 0) {
        setPermitirExcluir(false);
      }

      if (permissaoImprimir.length <= 0) {
        setPermitirImprimir(false);
      }
    }
  }, []);

  const handleFind = () => {
    const searchFilter = { findDataInicial, findDataFinal, findField };

    const _lazyParams = { ...lazyParams, first: 0, page: 0 };

    setLazyParams(_lazyParams);

    getData(searchFilter, cnpjEmpresa, 0, lazyParams.rows);
  };

  useEffect(() => {
    // handleFind();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSort = (event) => {
    setSortField(event.sortField);
    setSortOrder(event.sortOrder);

    setLazyParams(event);

    setDataTableList(orderBy(dataTableList, [sortField], [sortOrder]));
  };

  const rowExpansionTemplate = (data) => (
    <div>
      <DataTable value={data.listaEstoques} responsiveLayout="scroll">
        <Column field="nomeEmpresa" header="Empresa"></Column>
        <Column field="qtdeDeposito" header="Qtde Depósito"></Column>
        <Column field="qtdePrateleira" header="Qtde Prateleira"></Column>
      </DataTable>
    </div>
  );

  function fillExpander() {
    const _return = multiEmpresa ? <Column expander style={{ width: '3em' }} /> : null;

    return _return;
  }

  function handleCnpjEmpresa(e) {
    setCnpjEmpresa(e.target.value);

    dispatch(actions.cnpjEmpresaChange({ cnpjEmpresa: e.target.value }));

    setDataTableList([]);

    getData('', e.target.value, 0, lazyParams.rows);
  }

  function selecaoEmpresa() {
    if (!cadastroSincronizado && listaEmpresas.length > 1) {
      return (
        <div className="row form-elo">
          <div className="col-12">
            <div className="p-inputgroup">
              <Input100px className="p-inputgroup-addon">
                Empresa
              </Input100px>
              <Dropdown
                options={listaEmpresas}
                value={cnpjEmpresa}
                emptyMessage="Nenhum registro a ser exibido"
                onChange={e => handleCnpjEmpresa(e)}
              />
            </div>
          </div>
        </div>
      );
    }
    return '';
  }

  const onPage = (event) => {
    setLazyParams(event);

    const searchFilter = { findDataInicial, findDataFinal, findField };

    getData(searchFilter, cnpjEmpresa, event.page, event.rows);
  };

  // eslint-disable-next-line consistent-return
  const handleRegister = () => {
    if (!permitirCadastrar) {
      toast.info('Usuário não autorizado a cadastrar.');
      return false;
    }
    const id = 0;

    setPayloadCadastro({ id });

    setShowCadastro(true);
  };

  // eslint-disable-next-line consistent-return
  const handleEdit = (e) => {
    e.preventDefault();

    if (!permitirEditar) {
      toast.info('Usuário não autorizado a editar.');
      return false;
    }

    const id = get(selectedItens, 'id', 0);
    const cnpj = get(selectedItens.empresa, 'cnpj', 0);

    if (id > 0) {
      setPayloadCadastro({ id, cnpj });
      setShowCadastro(true);
    } else {
      toast.warning('Por favor, selecione o registro para edição');
    }
  };

  // eslint-disable-next-line consistent-return
  const handleFechar = (e) => {
    e.preventDefault();

    if (!permitirFechar) {
      toast.info('Usuário não autorizado a fechar ticket.');
      return false;
    }

    const id = get(selectedItens, 'id', 0);

    if (id > 0) {
      setShowFecharConfirmDialog(true);
    } else {
      toast.warning('Por favor, selecione o registro para fechamento');
    }
  };

  // eslint-disable-next-line consistent-return
  const handleDelete = (e) => {
    e.preventDefault();

    if (!permitirExcluir) {
      toast.info('Usuário não autorizado a excluir.');
      return false;
    }

    const id = get(selectedItens, 'id', 0);

    if (id > 0) {
      setShowConfirmDialog(true);
    } else {
      toast.warning('Por favor, selecione o registro para exclusão');
    }
  };

  const onHideCadastro = () => {
    setShowCadastro(false);
  };

  const childToParentConsulta = (payloadDataTableList, id) => {
    if (payloadDataTableList) {

      if (id > 0) {
        const index = dataTableList.map(e => e.id).indexOf(id);

        if (index >= 0) {
          dataTableList[index] = payloadDataTableList.data;
        }
      } else {
        const _dataTableList = [];
        _dataTableList.push(payloadDataTableList.data);

        setDataTableList(_dataTableList);
      }

      handleFind();
    }

    setShowCadastro(false);
  };

  const confirmDelete = async () => {
    try {
      setIsLoading(true);

      const idExclusao = get(selectedItens, 'id', 0);

      if (idExclusao > 0) {
        await axiosAdmin.delete(`/osaparelho/${idExclusao}`);

        const searchFilter = { findDataInicial, findDataFinal };

        getData(searchFilter, 0, lazyParams.rows);

        setSelectedItens(null);

        setShowConfirmDialog(false);
        toast.success('Registro excluído com sucesso');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível excluir o registro');
    } finally {
      setIsLoading(false);
    }
  };

  const cancelDelete = () => {
    setShowConfirmDialog(false);
  };

  const confirmDialogFooter = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmDelete} />
      <Button label="Não" icon="pi pi-times" onClick={cancelDelete} />
    </div>
  );

  const confirmFechar = async () => {
    try {
      setIsLoading(true);

      const idFechar = get(selectedItens, 'id', 0);

      if (idFechar > 0) {
        await axiosAdmin.put(`/osaparelho/fechar/${idFechar}`);

        const searchFilter = { findDataInicial, findDataFinal };

        getData(searchFilter, 0, lazyParams.rows);

        setSelectedItens(null);

        setShowFecharConfirmDialog(false);
        toast.success('Fechamento do ticket concluído com sucesso');
      }
    } catch (err) {
      displayErrors(err, dispatch, navigate, '', 'Não foi possível fechar o ticket');
    } finally {
      setIsLoading(false);
    }
  };

  const cancelFechar = () => {
    setShowFecharConfirmDialog(false);
  };

  const confirmFecharDialogFooter = (
    <div>
      <Button label="Sim" icon="pi pi-check" onClick={confirmFechar} />
      <Button label="Não" icon="pi pi-times" onClick={cancelFechar} />
    </div>
  );

  return (
    <>
      <Loading isLoading={isLoading} />

      <Dialog
        header=""
        closeOnEscape={false}
        visible={showCadastro}
        style={{ width: '98vw' }}
        onHide={() => onHideCadastro()}
        className="dialog-cadastro"
      >
        <CadastroOrdemServicoAparelho
          payloadCadastro={payloadCadastro}
          childToParentConsulta={childToParentConsulta}
        />
      </Dialog>

      <Card>
        <div>
          <div className="card mb-3">
            <h6 className="card-header" style={{ textAlign: 'center' }}>Ordem de Serviço</h6>
          </div>
          {selecaoEmpresa()}

          <div className="row form-elo">
            <div className="col-lg-3">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Data inicial
                </Input155px>
                <Calendar
                  id="mask"
                  showIcon
                  mask="99/99/9999"
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showOnFocus={false}
                  locale="pt-BR"
                  value={findDataInicial}
                  onChange={(e) => setFindDataInicial(e.value)}
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div className="p-inputgroup">
                <Input155px className="p-inputgroup-addon">
                  Data final
                </Input155px>
                <Calendar
                  id="mask"
                  showIcon
                  mask="99/99/9999"
                  dateFormat="dd/mm/yy"
                  showButtonBar
                  showOnFocus={false}
                  locale="pt-BR"
                  value={findDataFinal}
                  onChange={(e) => {
                    setFindDataFinal(e.value);
                    const consultaEstampariaPedidoDataInicial = findDataInicial;
                    const consultaEstampariaPedidoDataFinal = e.value;
                    dispatch(actions.consultaEstampariaPedidoDataInicialChange({ consultaEstampariaPedidoDataInicial, consultaEstampariaPedidoDataFinal }));
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-8">
              <div className="p-inputgroup">
                <Input100px className="p-inputgroup-addon">
                  Buscar por:
                </Input100px>
                <InputCampoBusca155px className="p-inputgroup-addon">
                  {columnsSetup.find(element => element.field === sortField).header}
                </InputCampoBusca155px>
                <InputText
                  maxLength={250}
                  value={findField}
                  onChange={e => setFindField(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-4">
              <button
                type="button"
                title="Buscar"
                className="btn btn-success"
                style={{ borderRadius: '15px' }}
                onClick={handleFind}
              >
                <i className="pi pi-search"></i>
              </button>
              <button
                disabled={somenteLeitura}
                type="button"
                title="Cadastrar"
                className="btn btn-primary"
                style={{ borderRadius: '15px' }}
                onClick={handleRegister}
              >
                <i className="pi pi-plus"></i>
              </button>
              <button
                disabled={somenteLeitura}
                type="button"
                title="Editar"
                className="btn btn-warning"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleEdit(e)}
              >
                <i className="pi pi-pencil"></i>
              </button>
              <button
                disabled={somenteLeitura}
                type="button"
                title="Fechar"
                className="btn btn-info"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleFechar(e)}
              >
                <i className="pi pi-check-square"></i>
              </button>
              <button
                disabled={somenteLeitura}
                type="button"
                title="Excluir"
                className="btn btn-danger"
                style={{ borderRadius: '15px' }}
                onClick={(e) => handleDelete(e)}
              >
                <i className="pi pi-trash"></i>
              </button>
            </div>
          </div>
        </div>
        <br />

        <div className="card">
          <DataTable
            id="produtos"
            value={dataTableList}
            expandedRows={expandedRows}
            onRowToggle={(e) => setExpandedRows(e.data)}
            responsiveLayout="scroll"
            rowExpansionTemplate={rowExpansionTemplate}
            dataKey="id"
            size="small"
            stripedRows
            sortField={sortField}
            sortOrder={sortOrder}
            onSort={onSort}
            selectionMode="single"
            selection={selectedItens}
            loading={isLoading}
            onSelectionChange={e => setSelectedItens(e.value)}
            selectableRows
            paginator
            lazy
            first={lazyParams.first}
            rows={lazyParams.rows}
            totalRecords={totalRecords}
            onPage={onPage}
            emptyMessage="Nenhum registro a ser exibido"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="{currentPage} de {totalPages}"
            rowsPerPageOptions={[10, 25, 50]}
          >
            {fillExpander()}
            {fillColumns(columnsSetup)}
          </DataTable>
        </div>

      </Card>

      <Dialog
        header="Confirmação"
        visible={showConfirmDialog}
        style={{ width: '50vw' }}
        footer={confirmDialogFooter}
        onHide={() => setShowConfirmDialog(false)}
      >
        <p>
          Excluir o registro?
        </p>
      </Dialog>

      <Dialog
        header="Confirmação"
        visible={showFecharConfirmDialog}
        style={{ width: '50vw' }}
        footer={confirmFecharDialogFooter}
        onHide={() => setShowFecharConfirmDialog(false)}
      >
        <p>
          Fechar o ticket?
        </p>
      </Dialog>
    </>
  );
}
